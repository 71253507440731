import {useState, useRef} from "react";
import useStickyHeader from "./useStickyHeader";
import * as Icon from 'react-bootstrap-icons';

const HeaderCompany = ({btnStyle, HeaderSTyle}) => {
    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky56' : ''}`

    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-3 col-md-6 col-4">
                            <h3 style={{verticalAlign:'bottom', display:'table-cell'}}>DKI</h3>
                        </div>
                        <div className="col-lg-9 col-md-6 col-8 position-static">
                            <div className="header-right">
                                   <span><Icon.EnvelopeFill/> info@dkinnovations.me</span>

                                   <span style={{marginLeft:"15px"}}><Icon.PhoneFill/> +382 69 956 473, +374 98 270 802</span>                            
                            </div>  
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default HeaderCompany;