import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import * as Icons from "react-bootstrap-icons"
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <Icons.Joystick />,
        title: 'Game Development',
        description: 'Game Development based on Unity/C#/.NET stack for mobile, desktop platforms, with multiplayer capabilities'
    },
    {
        icon: <Icons.Code />,
        title: 'Software Development',
        description: 'Desktop application development using C#/.NET Core, WPF, WF and other technologies in .NET ecosystem'
    },
    {
        icon: <Icons.BadgeVr />,
        title: 'VR Development',
        description: 'VR game, experience and app development using Unity/C# stack.'
    },
]

const ServiceDKI = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceDKI;