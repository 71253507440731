import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';

import Company from './pages/Company';
// Import Css Here
import './assets/scss/style.scss';

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + '/'}`}
            exact
            component={Company}
          />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
