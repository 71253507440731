import React from 'react';
import SEO from "../common/SEO";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import HeaderCompany from '../common/header/HeaderCompany';
import ServiceDKI from '../elements/service/ServicesDKI';
import SplitDKI from '../elements/split/SplitDKI';
import PortfolioDKI from '../elements/portfolio/PortfolioDKI';
import BrandDKI from '../elements/brand/BrandDKI';



const Company = () => {
    return (
        <>
            <SEO title="DIKEY INNOVATIONS - MONTENEGRO" />
            <main className="page-wrapper">
                <HeaderCompany btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-750">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-left">
                                    <span className="subtitle">SOFTWARE DEVELOPMENT COMPANY</span>
                                    <h1 className="title theme-gradient display-one">DIKEY INNOVATIONS</h1>
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Game Development</li>
                                        <li><span className="icon"><FiCheck /></span> Software Engineering</li>
                                        <li><span className="icon"><FiCheck /></span> Multiplayer Networking Solutions</li>
                                    </ul>
                                    <div className="button-group mt--40">
                                        <a className="btn-default btn-medium round btn-icon" href="mailto:info@dkinnovations.me">Contact Us <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                                <div className="frame-image">
                                    <img src="./images/company-images/Podgorica-590x394.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">We are providing PC, mobile and VR game development services</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Providing software, game and virtual reality development services with innovative approach. Multiplayer games, microservice based architecture.</p>
                                <div className="readmore-btn">
                                    <a className="btn-read-more" href="#services"><span>View More</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />
                {/* Start Service Area  */}
                <div id='services' className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services"
                                    description = ""
                                    />
                            </div>
                        </div>

                        <ServiceDKI 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <SplitDKI />

                <div className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Projects"
                                        title = "Why People Choose Us!"
                                        description = ""
                                    />
                            </div>
                        </div>
                        <PortfolioDKI Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--50 portfolio" />
                    </div>
                </div>

                <Separator />

                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">Our Technical Stack</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandDKI brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                <Copyright />
            </main>
        </>
    )
}
export default Company;
