import React from 'react';
import {Link} from "react-router-dom";

const PortfolioItem = ({ portfolio, isActive }) => {
    const id = `portfolio_item_${portfolio.id}`;
    return (
        <div className="rwt-card" id={id}>
            <div className="inner">
                <div className="thumbnail">
                    <figure className="card-image">
                        <a href={isActive?process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`: `#${id}`}>
                            <img className="img-fluid img-gray" src={portfolio.portfolioImage} alt="Portfolio-01"/>
                        </a>
                    </figure>
                    <a href={isActive?process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`:`#${id}`} className="rwt-overlay"></a>
                </div>
                <div className="content">
                    <h5 className="title mb--10">
                        <a href={isActive?process.env.PUBLIC_URL + `/portfolio-details/${portfolio.id}`:`#${id}`}>
                            {portfolio.title}
                        </a>
                    </h5>
                    <span className="subtitle b2 text-capitalize">{portfolio.category}</span>
                </div>
            </div>
        </div>
    )
}
export default PortfolioItem;